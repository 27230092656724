import { scrollWindowTo, getStickyHeaderHeight } from 'core/toolbox/scroll';
import { appendParamToURL, removeParamFromURL } from 'widgets/toolbox/util';
/**
 * @typedef {ReturnType<typeof import('widgets/search/ProductListingMgr').default>} BaseProductListingMgr
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<ReturnType<typeof import('core/search/RefinementsGridToggle').default>>} refinementsGridToggle
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputSelect').default>>} InputSelect
 * @typedef {InstanceType<typeof import('widgets/Widget').default>} widget
 */

import { RefElement } from 'widgets/toolbox/RefElement';

const IDEAS = 'ideas';
const PRODUCTS = 'products';
const GRID_UPDATING_EVENT = 'products.grid.updated';
const GTM_DATALAYER_EVENT = 'gtm.datalayer.event';
const GTM_EVENT = 'data-layer-event';
const THEMATIC = 'thematic=true';

/**
 * @param {BaseProductListingMgr} BaseProductListingMgr Base widget for extending
 * @returns {typeof ProductListingMgr} ProductListingMgr widget
 */
export default function (BaseProductListingMgr) {
    /**
     * @category widgets
     * @subcategory search
     * @class ProductListingMgr
     * @augments BaseProductListingMgr
    */
    class ProductListingMgr extends BaseProductListingMgr {
        prefs() {
            return {
                refinedByIdeas: false,
                keywordSearch: false,
                classesProductGridAlternative: 'm-one_column',
                classesInited: 'm-inited',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.setGridType();
            this.scrollToSavedTile();

            this.lazyloadAllowed = false;

            this.latestHref = {
                [IDEAS]: undefined,
                [PRODUCTS]: undefined
            };

            this.inlineSlots = document.querySelectorAll('.b-plp_grid_slot');

            if (this.inlineSlots.length) {
                window.addEventListener('load', this.calculateInlineSlotWidth.bind(this));
            }

            this.eventBus().on(GRID_UPDATING_EVENT, 'onGridUpdated');
            this.eventBus().on('quickview.show.previous', 'showPreviousQuickView');
            this.eventBus().on('quickview.show.next', 'showNextQuickView');
            this.eventBus().on('productlistingmgr.execute', 'executeCallback');
            this.eventBus().on('update.product.list.grid.view', 'updateView');
        }

        scrollToSavedTile() {
            var anchorTileID = window.sessionStorage.getItem('anchorTileID');

            if (!this.prefs().firstPage && anchorTileID) {
                this.getById(anchorTileID, tile => {
                    // prevent default scroll restoration
                    if ('scrollRestoration' in window.history) {
                        window.history.scrollRestoration = 'manual';
                    }

                    scrollWindowTo(tile.ref('self').get(), false, {
                        behavior: 'auto',
                        top: -parseInt(window.sessionStorage.getItem('anchorTileOffset') || '0', 10),
                        left: 0
                    });
                });
            }

            window.sessionStorage.removeItem('anchorTileID');
            window.sessionStorage.removeItem('anchorTileOffset');
        }

        /**
         * @description Basically injects self in scope of callback and executes it
         * Used in case of access to `ProductListingMgr` from other widgets, not directly related
         * @param {Function} cb callback to execute
         * @returns {void}
         */
        executeCallback(cb) {
            if (cb) {
                cb.apply(null, [this]);
            }
        }

        /**
         * @description Get tile index in grid by provided product ID
         * @param {string} productId product ID
         * @returns {number} tile index
         */
        getTileIndex(productId) {
            let currentTileIndex = 0;
            let tileIndex = 0;

            const ProductTile = this.getConstructor('productTile');

            this.eachChild(child => {
                if (child instanceof ProductTile) {
                    if (child.config.productId === productId) {
                        tileIndex = currentTileIndex;
                    }

                    currentTileIndex++;
                }
            });

            return tileIndex;
        }

        /**
         * @description Checks if it's very first tile in search results
         * @param {string} productId product ID
         * @returns {boolean} is first tile
         */
        isFirstTile(productId) {
            const tileIndex = this.getTileIndex(productId);

            let hasPrevProducts = false;

            this.has('loadPrevBlock', loadPrevBlock => {
                hasPrevProducts = !!loadPrevBlock.data('hasPrev');
            });

            return tileIndex === 0 && !hasPrevProducts;
        }

        /**
         * @description Checks if it's very last tile in search results
         * @param {string} productId product ID
         * @returns {boolean} is last tile
         */
        isLastTile(productId) {
            const tileIndex = this.getTileIndex(productId);

            let hasMoreProducts = false;

            this.has('loadMoreBlock', loadMoreBlock => {
                hasMoreProducts = !!loadMoreBlock.data('hasMore');
            });

            return tileIndex === (this.getProductTilesCount() - 1) && !hasMoreProducts;
        }

        /**
         * @description Show Quick View for tile with certain index
         * In case of last product tile + 1 and "load more" is shown - performs "load more" action
         * @param {number} index tile index to show quick view
         * @returns {void}
         */
        showQuickViewByIndex(index) {
            let targetProductTile = null;
            let currentTileIndex = 0;

            const ProductTile = this.getConstructor('productTile');

            this.eachChild(child => {
                if (child instanceof ProductTile) {
                    if (index === currentTileIndex) {
                        targetProductTile = child;
                    }

                    currentTileIndex++;
                }
            });

            if (targetProductTile) {
                // @ts-ignore
                targetProductTile.triggerQuickView();
            } else if (index < 0) {
                // TODO: find proper approach once load previous will be handled
                this.loadPrev(this.ref('loadPrevButton'));
            } else if (index >= this.getProductTilesCount()) {
                this.triggerQuickViewIndex = index;
                this.loadMore(this.ref('loadMoreButton'));
            }
        }

        /**
         * @description Show Quick View for previous tile in a grid
         * @param {string} productId product ID
         * @returns {void}
         */
        showPreviousQuickView(productId) {
            this.showQuickViewByIndex(
                this.getTileIndex(productId) - 1
            );
        }

        /**
         * @description Show Quick View for next tile in a grid
         * @param {string} productId product ID
         * @returns {void}
         */
        showNextQuickView(productId) {
            this.showQuickViewByIndex(
                this.getTileIndex(productId) + 1
            );
        }

        /**
         * @description Set initial grid type for different grid modes according to cookies
         * @returns {void}
         */
        setGridType() {
            this.getById(
                'refinementsGridToggle',
                (/** @type {refinementsGridToggle} */refinementsGridToggle) => refinementsGridToggle.setGridType()
            );
        }

        /**
         * @description Get total products tiles count, presents in a grid
         * @returns {number} total products tiles count, presents in a grid
         */
        getProductTilesCount() {
            let productTilesCount = 0;
            const ProductTile = this.getConstructor('productTile');

            this.eachChild(child => {
                if (child instanceof ProductTile) {
                    productTilesCount++;
                }
            });

            return productTilesCount;
        }

        /**
         * @description On grid updated:
         * Reflects an actual information of viewed products after loading more products chunks
         * Trigger quick view if needed
         * @param {object} [data] Rerender data when click on LoadMore and LoadPrev
         * @returns {void}
         */
        onGridUpdated(data) {
            this.updateGridInfo();

            if (this.triggerQuickViewIndex !== undefined) {
                this.showQuickViewByIndex(this.triggerQuickViewIndex);
                this.triggerQuickViewIndex = undefined;
            }

            if (data) {
                // @ts-ignore
                this.updateMetaLinks({ url: data.url, model: data.model });
            }
        }

        /**
         * @description Update canonical, prev and next link while rerender grid
         * @param {object} data Rerender data when click on LoadMore and LoadPrev
         * @returns {Promise} return Promise
         */
        updateMetaLinks(data) {
            // @ts-ignore
            var url = data.url;
            // @ts-ignore
            var model = data.model;

            this.updateCanonical(url);
            this.updatePaginationPrev(url, model);
            this.updatePaginationNext(url, model);

            return Promise.resolve();
        }

        /**
         * @description Update canonical link while rerender grid
         * @param {string} url - history.showUrl
         * @returns {void}
         */
        updateCanonical(url) {
            var canonical = this.ref('html').get()?.querySelectorAll('[rel="canonical"]')[0];

            canonical?.setAttribute('href', url);
        }

        /**
         * @description Update prev link while rerender grid
         * @param {string} url - history.showUrl
         * @param {object} model updated search model
         * @returns {void}
         */
        updatePaginationPrev(url, model) {
            if (model) {
                var linkRelPrev = this.ref('html').get()?.querySelectorAll('[rel="prev"]');

                var productSearch = model;
                // @ts-ignore
                var sz = productSearch.pageSize;
                // @ts-ignore
                var start = productSearch.pageSize * productSearch.pageNumber;
                // @ts-ignore
                var prevStart = productSearch.pageSize * (productSearch.pageNumber - 1);
                var prevUrl = url;

                var createLinkPrev = '<link rel="prev" href=""/>';
                var canonical = this.ref('html').get()?.querySelectorAll('[rel="canonical"]')[0];

                if (!linkRelPrev?.length) {
                    // @ts-ignore
                    canonical?.insertAdjacentHTML('beforebegin', createLinkPrev);
                    linkRelPrev = this.ref('html').get()?.querySelectorAll('[rel="prev"]');
                }

                if (sz !== start && prevStart > 0) {
                    prevUrl = this.replaceParameterInUrl(prevUrl, 'start', prevStart);
                    prevUrl = this.replaceParameterInUrl(prevUrl, 'sz', sz);
                } else {
                    prevUrl = removeParamFromURL(prevUrl, 'sz');
                    prevUrl = removeParamFromURL(prevUrl, 'start');
                }

                if (start === 0 && linkRelPrev?.length) {
                    linkRelPrev[0].remove();
                } else {
                    // @ts-ignore
                    linkRelPrev[0].setAttribute('href', prevUrl);
                }
            }
        }

        /**
         * @description Update next link while rerender grid
         * @param {string} url - history.showUrl
         * @param {object} model updated search model
         * @returns {void}
         */
        updatePaginationNext(url, model) {
            if (model) {
                var linkRelNext = this.ref('html').get()?.querySelectorAll('[rel="next"]');
                var productSearch = model;
                // @ts-ignore
                var nextStart = productSearch.pageSize * (productSearch.pageNumber + 1);
                // @ts-ignore
                var isLast = nextStart >= productSearch.count;
                var nextUrl = url;

                if (isLast && linkRelNext?.length) {
                    linkRelNext[0].remove();
                } else {
                    nextUrl = this.replaceParameterInUrl(nextUrl, 'start', nextStart);
                    // @ts-ignore
                    nextUrl = this.replaceParameterInUrl(nextUrl, 'sz', productSearch.pageSize);
                    // @ts-ignore
                    linkRelNext[0]?.setAttribute('href', nextUrl);
                }
            }
        }

        /**
         * @description Replace paramenter in Url
         * @param {string} url - any url
         * @param {string} name name of paramenter
         * @param {string} value value of paramenter
         * @returns {string} new url
         */
        replaceParameterInUrl(url, name, value) {
            url = removeParamFromURL(url, name);

            return appendParamToURL(url, name, value);
        }

        /**
         * @description Update product grid counters (viewing/viewed products)
         * @returns {void}
         */
        updateGridInfo() {
            let currentStart = 1;
            const selfRef = this.ref('self').get();

            this.has('loadPrevButton', loadPrevButton => {
                currentStart = Number(loadPrevButton.data('currentStart')) || 1;
            });

            const currentlyViewedCount = new RefElement(
                // @ts-ignore
                selfRef?.querySelectorAll('[data-ref="currentlyViewedCount"]')
            );
            const currentlyViewingCount = new RefElement(
                // @ts-ignore
                selfRef?.querySelectorAll('[data-ref="currentlyViewingCount"]')
            );

            if (currentlyViewedCount && currentlyViewedCount.length) {
                currentlyViewedCount.setText(
                    (this.getProductTilesCount() + currentStart - 1).toString()
                );
            }

            if (currentlyViewingCount && currentlyViewingCount.length) {
                currentlyViewingCount.setText(currentStart.toString());
            }
        }

        /**
         * @description Shows full refinements set for target refinements accordion item
         * @param {RefElement} btn clicked `show more` button
         * @returns {void}
         */
        showMoreRefinements(btn) {
            const showMoreResultsContainer = btn.get()?.parentElement;
            const refinementsBlock = showMoreResultsContainer?.parentElement;

            if (refinementsBlock) {
                // @ts-ignore
                const refinementsItems = new RefElement(Array.from(refinementsBlock.children));

                refinementsItems.show();

                const showMoreResultsContainerRefElement = new RefElement([showMoreResultsContainer]);

                showMoreResultsContainerRefElement.hide();
            }
        }

        /**
         * @description Toggle ideas search
         * @param {RefElement} checkbox toggle checkbox
         * @returns {void}
         */
        toggleIdeas(checkbox) {
            this.eventBus().emit(GTM_DATALAYER_EVENT, {
                event: GTM_EVENT,
                eventCategory: 'Filter toggle click',
                eventAction: !this.prefs().refinedByIdeas ? 'ideas' : 'products',
                // @ts-ignore
                eventLabel: this.prefs().category
            });
            // @ts-ignore
            this.updateView(checkbox);
        }

        /**
         * @description handles update view
         * @param {RefElement} button - clicked button
         * @param {object} data - additional data params
         * @param {boolean} data.refinementPanelUpdate - refinement update flag
         */
        handleUpdateView(button, data) {
            let updateURL = button.data('href');
            const refinementSelected = button.data('checked');
            const alertMessage = refinementSelected
                ? this.prefs().accessibilityAlerts.filterremoved
                : this.prefs().accessibilityAlerts.filtersapplied;

            if (window.location.href.includes('/th/') || window.location.href.includes(THEMATIC)) {
                updateURL += '&thematic=true';
            }

            // @ts-ignore
            this.updateByUrl(updateURL, undefined, true)
                .then(() => {
                    this.accessibilityAlert(alertMessage);

                    if (data && data.refinementPanelUpdate) {
                        this.eventBus().emit('refinement.panel.show');
                    }
                });
        }

        /**
         * @description Update View
         * Has additional logic as per requirements when switching views between ideas/products
         * @listens dom#click
         * @param {RefElement|widget} button Target element
         * @param {object} data - additional config
         * @returns {void}
         */
        // @ts-ignore
        updateView(button, data) {
            if (button.data('gtmEventLabel') && !button.config?.checked) {
                this.eventBus().emit(GTM_DATALAYER_EVENT, {
                    event: GTM_EVENT,
                    eventCategory: this.prefs().refinedByIdeas ? 'Ideas interaction' : 'Filter interaction',
                    eventAction: 'Apply',
                    eventLabel: button.data('gtmEventLabel'),
                    eventSublabel: button.data('gtmEventSublabel')
                });
            }

            if (!this.prefs().keywordSearch) {
                this.handleUpdateView(button, data);

                return;
            }

            const isToggleIdeasButton = button.data('toggleIdeas');
            const currentToggleIdeasState = this.prefs().refinedByIdeas
                ? IDEAS : PRODUCTS;
            const futureToggleIdeaState = currentToggleIdeasState === IDEAS
                ? PRODUCTS : IDEAS;

            // @ts-ignore
            if (isToggleIdeasButton && this.latestHref[futureToggleIdeaState]) {
                // @ts-ignore
                this.updateByUrl(this.latestHref[futureToggleIdeaState], undefined, true)
                    .then(() => this.accessibilityAlert(
                        this.prefs().accessibilityAlerts.productlistupdated
                    ));

                return;
            }

            if (!isToggleIdeasButton) {
                // @ts-ignore
                this.latestHref[currentToggleIdeasState] = button.data('href');
                // @ts-ignore
                this.latestHref[futureToggleIdeaState] = undefined;
            }

            this.handleUpdateView(button, data);
        }

        /**
         * @description Load more products
         * @listens dom#click
         * @param {RefElement} button Target element
         * @returns {void}
         */
        loadMore(button) {
            this.eventBus().emit(GTM_DATALAYER_EVENT, {
                event: GTM_EVENT,
                eventCategory: 'Key interactions',
                eventAction: 'Load more'
            });

            const href = button.data('show-url');
            const history = {
                showUrl: button.attr('href'),
                showAjaxUrl: button.data('show-ajax-url')
            };
            var url = '';

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
            const model = window.searchModel;

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadMoreBlock').remove();
            this.loadChunk(url, (/** @type {string} */ response) => {
                if (typeof response === 'string') {
                    this.ref('productGrid').append(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                    setTimeout(() => this.eventBus().emit(GRID_UPDATING_EVENT, {
                        url: history.showUrl,
                        model: model
                    }), 0);
                }
            }, true, history);
        }

        /**
         * @description Update product grid by url
         * @param {string} url Update URL
         * @param {refElement} ref Reference element
         * @param {boolean} handleHistory Handle history flag
         * @returns {Promise<object|null>} Promise object represents server response with products list markup
         */
        // @ts-ignore
        updateByUrl(url, ref, handleHistory) {
            return super.updateByUrl(url, ref, handleHistory)
                .then(response => {
                    this.setGridType();

                    this.eventBus().emit('virtual.page.load');

                    return response;
                });
        }

        /**
         * @description No Result Combination handler
         * Added empty object as a parameter in order not to rise error
         * @returns {void}
         */
        handleNoResult() {
            // @ts-ignore
            this.getById('noResultPopup', popup => popup.showModal({}));
        }

        /**
         * @description toggle grid type class
         * @param {boolean} isAlternativeGrid widget container element
         * @returns {void}
         */
        toggleGridType(isAlternativeGrid) {
            this.ref('productGrid').toggleClass(this.prefs().classesProductGridAlternative, !!isAlternativeGrid);
        }

        /**
         * @description Executes on no refinements combination popup closed
         * @returns {void}
         */
        onNoResultPopupClosed() {
            let refinedByIdeas = false;

            const RefinementsPanel = this.getConstructor('refinementsPanel');

            this.eachChild(child => {
                if (child instanceof RefinementsPanel) {
                    // @ts-ignore
                    child.getRefinementsModel();
                    // @ts-ignore
                    refinedByIdeas = child?.model?.refinedByIdeas;
                }
            });

            if (!refinedByIdeas) {
                this.has('toggleIdeas', toggleIdeas => toggleIdeas.prop('checked', false));
            }
        }

        /**
         * @description Start lazyload of product tiles during window scroll
         * @returns {void}
         */
        startLazyload() {
            this.lazyloadAllowed = true;

            this.has('loadMoreButton', loadMoreButton => this.loadMore(loadMoreButton));
        }

        /**
         * @description On `lazyload` event triggered - send `loadMore` request
         * @returns {void}
         */
        lazyload() {
            if (!this.lazyloadAllowed) {
                return;
            }

            this.has('loadMoreButton', loadMoreButton => this.loadMore(loadMoreButton));
        }

        /**
         * @description Update Grid
         * @listens dom#change
         * @param {InputSelect} select Target element
         * @returns {void}
         */
        updateGrid(select) {
            const selectedSorting = select.getSelectedOptions();

            if (selectedSorting) {
                let url = selectedSorting.data('url');

                if (window.location.href.includes('/th/') || window.location.href.includes(THEMATIC)) {
                    url += '&thematic=true';
                }

                // @ts-ignore
                this.updateByUrl(url, undefined, true)
                    .then(() => this.afterUpdateGrid());
            }
        }

        /**
         * @description Handle History
         * @param {boolean} [isReplace] Replace flag
         * @param {object} [history] history object
         * @returns {void}
         */
        handleHistory(isReplace = false, history) {
            if (window.location.href.includes('/th/') || window.location.href.includes(THEMATIC)) {
                return;
            }

            const state = {
                ajaxUrl: history ? history.showAjaxUrl : this.prefs().showAjaxUrl
            };
            let historyURL = history ? history.showUrl : this.prefs().showUrl;
            const lcHref = window.location.href.toLowerCase();

            if (lcHref.includes('plppdpproduct') && (lcHref.includes('utm') || lcHref.includes('gclid'))) {
                historyURL = this.copyPaidParams(window.location.href, historyURL);
            }

            if (document.location.hash) {
                historyURL += document.location.hash;
            }

            if (isReplace) {
                window.history.replaceState(state, '', historyURL);
            } else {
                window.history.pushState(state, '', historyURL);
            }
        }

        /**
         * @description Copy Parameters from paid redirect
         * @param {string} [currentURL] URL with params
         * @param {string} [historyURL] history URL
         * @returns {string} Updated history URL
         */
        copyPaidParams(currentURL, historyURL) {
            var currentURLObj = new URL(currentURL);
            var historyURLObj = new URL(historyURL);

            currentURLObj.searchParams.forEach((value, key) => {
                if (key.toLowerCase().includes('utm') || key.toLowerCase().includes('gclid')) {
                    historyURLObj.searchParams.set(key, value);
                }
            });

            return historyURLObj.toString();
        }

        /**
         * @description after update grid events
         */
        afterUpdateGrid() {
            this.accessibilityAlert(this.prefs().accessibilityAlerts.sortingapplied);
        }

        /**
         * @description Busy
         * @returns {void}
         */
        busy() {
            super.busy();

            this.getById(
                'refinementsPanel',
                refinementsPanel => refinementsPanel.ref('panel').addClass(this.prefs().classesBusy).attr('aria-busy', 'true')
            );
        }

        /**
         * @description Unbusy
         * @returns {void}
         */
        unbusy() {
            super.unbusy();

            this.getById(
                'refinementsPanel',
                refinementsPanel => refinementsPanel.ref('panel').removeClass(this.prefs().classesBusy).attr('aria-busy', false)
            );
        }

        /**
         * @description Load previous products
         * @listens dom#click
         * @param {RefElement} button Target element
         * @returns {void}
         */
        loadPrev(button) {
            let firstAvailableTile;
            let firstAvailableTileOffset;

            this.has('productGrid', productGrid => {
                const productTiles = productGrid.get()?.querySelectorAll('[data-widget="productTile"]');

                if (productTiles && productTiles.length) {
                    firstAvailableTile = productTiles[0];
                    firstAvailableTileOffset = firstAvailableTile.getBoundingClientRect().top
                        - firstAvailableTile.offsetTop;
                }
            });

            const href = button.data('show-url');
            const history = {
                showUrl: button.attr('href'),
                showAjaxUrl: button.data('show-ajax-url')
            };
            var url = '';

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'productPositions' does not exist on type... Remove this comment to see the full error message
            const model = window.searchModel;

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadPrevBlock').remove();
            this.loadChunk(url, (/** @type {string} */ response) => {
                if (typeof response === 'string') {
                    this.ref('productGrid').prepend(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                    setTimeout(() => this.eventBus().emit(GRID_UPDATING_EVENT, {
                        url: history.showUrl,
                        model: model
                    }), 0);

                    if (firstAvailableTile) {
                        scrollWindowTo(firstAvailableTile, true, {
                            behavior: 'auto',
                            top: -(firstAvailableTileOffset - getStickyHeaderHeight())
                        });
                    }
                }
            }, true, history);
        }

        /**
         * @description Calculate Inline Slot Title's width
         * @returns {void}
         */
        calculateInlineSlotWidth() {
            this.inlineSlots?.forEach(item => {
                const inlineSlotsTitle = item.querySelector('.b-plp_grid_slot-title');

                if (inlineSlotsTitle) {
                    inlineSlotsTitle.style.width = Math.ceil(inlineSlotsTitle.getBoundingClientRect().width) + 'px';
                }

                item.classList.add(this.prefs().classesInited);
            });
        }
    }

    return ProductListingMgr;
}
